import React, {FC, useLayoutEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import AppStore from '../../store/AppStore';
import logo from '../../assets/images/logo.png';
import {Link, useLocation} from 'react-router-dom';
import GenderSelect from '../../components/GenderSelect';
import HomeRightHeader from "./assets/components/HomeRightHeader";
import home from "../../assets/icons/home.svg";
import chat from "../../assets/icons/chat.svg";
import gallery from "../../assets/icons/gallery.svg";
import generate from "../../assets/icons/generate.svg";
import star from "../../assets/icons/star.svg";
import chevron_down from "../../assets/icons/chevron_down.svg";
import MenuListItem from "./components/MenuListItem";
import {ReactSVG} from "react-svg";
import OutsideClick from "../../components/OutsideClick";

export type TMenu = { path: string, title?: string, smallTitle?: string, icon?: any, needUser?: boolean, }

export const menuData: TMenu[] = [
  {path: '/', title: 'EXPLORE', icon: home, needUser: false},
  {path: '/conversation', title: 'CHAT', icon: chat, needUser: true},
  {path: '/gallery', title: 'GALLERY', icon: gallery, needUser: true},
  {path: '/request-photo', title: 'GENERATE_IMAGE', smallTitle: 'GENERATE', icon: generate, needUser: true},
  {path: '/companions', title: 'MY_AI', icon: star, needUser: false}
];

interface Props {
}

const Header: FC<Props> = observer(() => {
  const _menuRef: any = useRef(null);
  const _rightRef: any = useRef(null);
  const location = useLocation();
  const [ready, setReady] = useState(false);
  const [genderVisible, setGenderVisible] = useState(false);
  const [menuList, setMenuList] = useState<TMenu[]>(menuData);
  const [hiddenMenuList, setHiddenMenuList] = useState<TMenu[]>([]);
  const [menuHiddenVisible, setMenuHiddenVisible] = useState(false);

  useLayoutEffect(() => {
    if (location.pathname === '/') {
      setGenderVisible(false);
      const onScroll = () => window.scrollY > 150 ? setGenderVisible(true) : setGenderVisible(false);
      window.addEventListener('scroll', onScroll, {passive: true});
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [location.pathname]);

  useLayoutEffect(() => {
    computedWidth();
  }, [AppStore.ready, menuList?.length]);

  const computedWidth = () => {
    const menuRef = _menuRef?.current?.getBoundingClientRect();
    const rightRef = _rightRef?.current?.getBoundingClientRect();
    const diff = hiddenMenuList?.length ? 60 : 12;
    if (menuRef?.x + menuRef?.width + diff >= rightRef?.x) {
      setMenuList(prevState => {
        setHiddenMenuList((prevHiddenState: any) => {
          return [...prevHiddenState, prevState.at(-1)]
        })
        return prevState.slice(0, -1);
      })
    } else {
      setReady(true)
    }
  }

  const toggleMenuVisible = (e: any) => {
    e.stopPropagation();
    setMenuHiddenVisible(prevState => !prevState)
  }

  return (
    <>
      <header className={'header__main'}>
        <div className={`header${genderVisible ? ' scrolled' : ''}`}>
          <div className='header-left'>
            <Link to={'/'} className='header-logo'><img src={logo} alt='logo'/></Link>
          </div>
          <div className='container header-center'>
            {location.pathname === '/' && <GenderSelect icon/>}
            {/*<nav className='nav__invisible' >*/}
            {/*  <ul>*/}
            {/*    {menuData?.map((menu) => (*/}
            {/*      <MenuListItem menu={menu} key={menu.path}/>*/}
            {/*    ))}*/}
            {/*  </ul>*/}
            {/*</nav>*/}
            <nav ref={_menuRef} className={!ready ? 'nav__invisible' : ''}>
              <ul>
                {menuList?.map((menu) => (
                  <MenuListItem menu={menu} key={menu.path}/>
                ))}
              </ul>
              {(hiddenMenuList?.length && ready)
                ?
                <OutsideClick outsideClickEvent={() => setMenuHiddenVisible(false)}
                              className={`nav__hidden_container${menuHiddenVisible ? ' visible' : ''}`}>
                  <ReactSVG src={chevron_down} className='react-icon nav__hidden_icon' onClick={toggleMenuVisible}/>
                  <div className="nav__hidden_list">
                    <ul>
                      {hiddenMenuList?.map((menu) => (
                        <MenuListItem menu={menu} key={menu.path} onClick={(e) => {
                          e.stopPropagation();
                          setMenuHiddenVisible(false)
                        }}/>
                      ))}
                    </ul>
                  </div>
                </OutsideClick>

                :
                null
              }
            </nav>
          </div>
          <div className='header-right' ref={_rightRef}>
            <HomeRightHeader/>
          </div>
        </div>
      </header>
    </>
  );
});

export default Header;
