import React, {FC} from 'react';
import girlReal from '../assets/images/girl_real.png';
import girlAnime from '../assets/images/girl_anime.png';
import maleAnime from '../assets/images/male_anile.png';
import maleReal from '../assets/images/male_real.png';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import ellipse from "../../../assets/icons/ellipse.svg";
import user from "../../../assets/icons/user-btn.svg";
import {Link} from "react-router-dom";
import {EGender, EModelStyle} from "../../../modules/rest";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import HomeStore from "../../../store/HomeStore";

const images: Record<EGender, Record<EModelStyle, any>> = {
  [EGender.Female]: {
    [EModelStyle.Real]: girlReal,
    [EModelStyle.Hyperanime]: girlAnime,
  },
  [EGender.Male]: {
    [EModelStyle.Real]: maleReal,
    [EModelStyle.Hyperanime]: maleAnime,
  },
}

interface Props {
}

const CreateAiListItem: FC<Props> = observer(({}) => {
  const {t} = useTranslation();

  return (
    <div className='col-6 col-md-4 col-lg-3' >
      <Link to={'/create-ai'} className="model-card create__ai_homeList-item">
        <img src={images[AppStore.gender][HomeStore?.style || EModelStyle.Real]} alt="girl"/>
        <div className="create__ai_homeList-border" />
        <div className='create__ai_homeList-text'>
          <div className='btn__user_animate-icon'>
            <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
            <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
            <ReactSVG src={user} className='react-icon'/>
          </div>
          <div className='mt-3'>{t('CREATE_YOUR_AI')}</div>
        </div>
      </Link>
    </div>
  );
})

export default CreateAiListItem;