import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {EModelStyle, IAsset, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {getImageByModel} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";
import CreateAIUpload from "./CreateAIUpload";
import {useLocation, useNavigate} from "react-router-dom";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
  image?: IAsset|null;
  onChangeImage: (image?: IAsset|null) => void;
  loading: boolean;
}

const Step1Style: FC<Props> = observer(({onStepChange, data, onChangeImage, image, loading}) => {
  const {t} = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const [hideStyle, setHideStyle] = useState(false);
  const [asset, setAsset] = useState<IAsset|null|undefined>(image);

  useLayoutEffect(() => {
    const isDouble = query?.get('double');
    setHideStyle(Boolean(isDouble));
    const style = query?.get('style') as EModelStyle;
    if (style && EModelStyle[style]) {
      query.delete('style');
      navigate({
        pathname: location.pathname,
        search: query.toString(),
      });
      onStepChange({style});
    }
  }, []);

  useEffect(() => {
    if (!image) setAsset(null);
  }, [image]);

  const handleChangeImage = (image?: IAsset|null) => {
    setAsset(image);
    onChangeImage(image);
  }

  const handleStepChange = (style: EModelStyle) => () => {
    onStepChange({style});
  }

  return (
    <div>
      {hideStyle || image
        ?
        null
        :
        <div>
          <div className='create__ai_style'>
            <div className={`row create__ai_list pb-0 ${image ? 'g-2 g-lg-3' : 'g-3'}`}>
              {Object.values(EModelStyle).map(style => (
                <div key={style} className={image ? 'col-6 col-sm-3 col-lg-2' : 'col-12 col-sm-6'}>
                  <StepItem
                    onClick={handleStepChange(style)}
                    active={style === data?.style}
                    size={image ? 'small' : undefined}
                    title={image ? (t(style.toUpperCase()) || '') : ''}
                  >
                    {/*<SuspenseImg src={getImageByModel(AppStore.gender, 'style', key)}  />*/}
                    <img src={getImageByModel(AppStore.gender, 'style', style)} alt=""/>
                    {!image
                      ?
                      <div
                        className='letter-uppercase align-self-end ms-3 text-24 text-truncate'>{t(style.toUpperCase())}</div>
                      :
                      null
                    }
                  </StepItem>
                </div>
              ))}
            </div>
          </div>
          <h4 className='text-center my-3 create__double_title'>{t('or')} <span
            className='text-accent'>{t('FIND_DOUBLE')}</span></h4>
        </div>
      }
      <CreateAIUpload
        image={asset}
        onChange={handleChangeImage}
        onClick={() => onChangeImage(asset)}
      />
    </div>
  );
})

export default Step1Style;