import React, {FC, useEffect} from 'react';


import HomeStore from "../../../store/HomeStore";
import {IModel} from "../../../modules/rest";
import TopCarouselItem from "./TopCarouselItem";
import {EffectCoverflow, Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/effect-coverflow';
import {observer} from "mobx-react";

interface Props {
  onClick: (item: IModel) => void;
}

const HomeMobileCarousel: FC<Props> = observer(({onClick}) => {

  return (
    <div className="top__carousel_mobile">
      {HomeStore.randomModels?.length
      ?
        <Swiper
          effect='coverflow'
          slidesPerView={3}
          grabCursor={false}
          centeredSlides={true}
          preventClicks={false}
          preventClicksPropagation={false}
          simulateTouch={false}
          coverflowEffect={{
            rotate: 5,
            stretch: 0,
            depth: 200,
            modifier: 2,
            slideShadows: false,
          }}
          loop
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectCoverflow, Autoplay]}
          className="mySwiper"
        >
          {HomeStore.randomModels?.map((item: IModel, i: number) => (
            <SwiperSlide key={i}>
              <TopCarouselItem
                item={item}
                onChat={() => onClick(item)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      :
      null
      }

    </div>
  );
})

export default HomeMobileCarousel;