import React, {FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import TopCarouselItem from "./TopCarouselItem";
import HomeStore from "../../../store/HomeStore";
import {observer} from "mobx-react";
import {IModel} from "../../../modules/rest";
import {isSmallDevice} from "../../../modules/utils";

interface Props {
  onAnswer: (model: IModel) => void;
}

const TopCarousel: FC<Props> = observer(({onAnswer}) => {
  const _ping: any = useRef(null);
  const _pingActive: any = useRef(null);
  const [ready, setReady] = useState(false);
  const [activeIdx, setActiveIdx] = useState<number|null>(1);
  const [positions, setPositions] = useState<number[]>([]);

  useLayoutEffect(() => {
    let len = HomeStore.randomModels?.length;
    if (len) {
      const arr = [];
      for (let i = 0; i < len; i++) {
        arr.push(i);
      }
      setPositions(arr);
    }

  }, [HomeStore.randomModels?.length]);

  const onFocus = () => {
    start();
  };

  const onBlur = () => {
    setReady(true)
    stop();
  };

  useEffect(() => {
    start(true);
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      stop();
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  const start = (init?: boolean) => {
    stop();
    if (isSmallDevice) return;
    _ping.current = setInterval(() => {
      setPositions(prevState => {
        _pingActive.current = setTimeout(() => {
          setActiveIdx(() => {
            const idx = prevState.findIndex(item => item === 1) + 1;
            if (idx >= prevState.length) return 0;
            return idx;
          })
        }, 2000);
        return prevState.map(idx => {
          if (idx === 0) return HomeStore.randomModels?.length - 1;
          return idx - 1;
        })
      })
      setActiveIdx(null);

      setReady(true);
    }, init ? 3500 : 5500);
  }

  const stop = () => {
    clearInterval(_ping.current);
  }


  return (
    <>
      <div className={`top-carousel${!ready ? ' without__start_animation' : ''}`}>
        {HomeStore.randomModels.map((item: any, i: number) => (
          <TopCarouselItem
            onBlur={onFocus}
            onHover={onBlur}
            idx={positions[i]}
            active={activeIdx === i}
            key={item.id}
            item={item}
            onChat={() => onAnswer(item)}
            disableCardClick
          />
        ))}
      </div>
    </>
  );
})

export default TopCarousel;